<template>
  <div class="admin">

    <div class="admin-area">

      <div class="row row-gutter-20">
        <div class="col-6">
          <admin-nav></admin-nav>
        </div>
        <div class="col-18">

          <header class="admin-header cf">
            <ul class="nav nav-header cf">
              <li>Dashboard</li>
            </ul>
            <div class="header-actions">

            </div>
          </header>

          <div class="admin-body">

            <h3>Heutige Abholungen</h3>
            <div v-if="pickups.length > 0">
              <table class="table">
                <thead>
                  <tr>
                    <th width="10%"></th>
                    <th width="20%">Abholung</th>
                    <th width="20%">Fahrzeug</th>
                    <th width="20%">Kunde</th>
                    <th width="20%">Standort</th>
                    <th width="10%"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="reservation in pickups" v-bind:key="reservation.id">
                    <td>
                      <span v-if="reservation.status == 'paid'" class="status status-green"></span>
                      <span v-if="reservation.status == 'ended'" class="status status-black"></span>
                      <span v-if="reservation.status == 'booked'" class="status status-yellow"></span>
                      <span v-if="reservation.status == 'canceled'" class="status status-red"></span>
                    </td>
                    <td>{{ reservation.pickup_timestamp }}</td>
                    <td><span v-if="reservation.vehicle">{{ reservation.vehicle.license_plate }}</span></td>
                    <td>{{ reservation.first_name }} {{ reservation.last_name }}</td>
                    <td>{{ reservation.pickup_location.name }}</td>
                    <td style="text-align:right">
                      <router-link :to="'/admin/reservations/'+reservation.id">
                        <span class="material-icons">launch</span>
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else>
              <div class="alert alert-info">
                Heute finden keine Abholungen statt.
              </div>
            </div>


            <h3>Heutige Rückgaben</h3>
            <div v-if="returns.length > 0">
              <table class="table">
                <thead>
                  <tr>
                    <th width="10%"></th>
                    <th width="20%">Abholung</th>
                    <th width="20%">Fahrzeug</th>
                    <th width="20%">Kunde</th>
                    <th width="20%">Standort</th>
                    <th width="10%"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="reservation in returns" v-bind:key="reservation.id">
                    <td>
                      <span v-if="reservation.status == 'paid'" class="status status-green"></span>
                      <span v-if="reservation.status == 'ended'" class="status status-black"></span>
                      <span v-if="reservation.status == 'booked'" class="status status-yellow"></span>
                      <span v-if="reservation.status == 'canceled'" class="status status-red"></span>
                    </td>
                    <td>{{ reservation.return_timestamp }}</td>
                    <td><span v-if="reservation.vehicle">{{ reservation.vehicle.license_plate }}</span></td>
                    <td>{{ reservation.first_name }} {{ reservation.last_name }}</td>
                    <td>{{ reservation.return_location.name }}</td>
                    <td style="text-align:right">
                      <router-link :to="'/admin/reservations/'+reservation.id">
                        <span class="material-icons">launch</span>
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else>
              <div class="alert alert-info">
                Heute finden keine Rückgaben statt.
              </div>
            </div>

            <hr>

            <h3>Morgige Abholungen</h3>
            <div v-if="pickups_tomorrow.length > 0">
              <table class="table">
                <thead>
                  <tr>
                    <th width="10%"></th>
                    <th width="20%">Abholung</th>
                    <th width="20%">Fahrzeug</th>
                    <th width="20%">Kunde</th>
                    <th width="20%">Standort</th>
                    <th width="10%"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="reservation in pickups_tomorrow" v-bind:key="reservation.id">
                    <td>
                      <span v-if="reservation.status == 'paid'" class="status status-green"></span>
                      <span v-if="reservation.status == 'ended'" class="status status-black"></span>
                      <span v-if="reservation.status == 'booked'" class="status status-yellow"></span>
                      <span v-if="reservation.status == 'canceled'" class="status status-red"></span>
                    </td>
                    <td>{{ reservation.pickup_timestamp }}</td>
                    <td><span v-if="reservation.vehicle">{{ reservation.vehicle.license_plate }}</span></td>
                    <td>{{ reservation.first_name }} {{ reservation.last_name }}</td>
                    <td>{{ reservation.pickup_location.name }}</td>
                    <td style="text-align:right">
                      <router-link :to="'/admin/reservations/'+reservation.id">
                        <span class="material-icons">launch</span>
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else>
              <div class="alert alert-info">
                Morgen finden keine Abholungen statt.
              </div>
            </div>


            <h3>Morgige Rückgaben</h3>
            <div v-if="returns_tomorrow.length > 0">
              <table class="table">
                <thead>
                  <tr>
                    <th width="10%"></th>
                    <th width="20%">Abholung</th>
                    <th width="20%">Fahrzeug</th>
                    <th width="20%">Kunde</th>
                    <th width="20%">Standort</th>
                    <th width="10%"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="reservation in returns_tomorrow" v-bind:key="reservation.id">
                    <td>
                      <span v-if="reservation.status == 'paid'" class="status status-green"></span>
                      <span v-if="reservation.status == 'ended'" class="status status-black"></span>
                      <span v-if="reservation.status == 'booked'" class="status status-yellow"></span>
                      <span v-if="reservation.status == 'canceled'" class="status status-red"></span>
                    </td>
                    <td>{{ reservation.return_timestamp }}</td>
                    <td><span v-if="reservation.vehicle">{{ reservation.vehicle.license_plate }}</span></td>
                    <td>{{ reservation.first_name }} {{ reservation.last_name }}</td>
                    <td>{{ reservation.return_location.name }}</td>
                    <td style="text-align:right">
                      <router-link :to="'/admin/reservations/'+reservation.id">
                        <span class="material-icons">launch</span>
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else>
              <div class="alert alert-info">
                Morgen finden keine Rückgaben statt.
              </div>
            </div>



          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import axios from 'axios'
import AdminNav from '../../../components/admin_nav'
import moment from 'moment'

export default {
  name: 'admin_dashboard',
  components: {
    AdminNav
  },
  data () {
    return {
      pickups: [],
      returns: [],
      pickups_tomorrow: [],
      returns_tomorrow: []
    }
  },
  methods: {
    get_data() {

      var date_today = moment(new Date()).format("DD-MM-YYYY");

      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/reservations?pickup_date='+date_today, { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        this.pickups = response.data.reservations;
      })
      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/reservations?return_date='+date_today, { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        this.returns = response.data.reservations;
      })

      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/reservations?pickup_date=tomorrow', { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        this.pickups_tomorrow = response.data.reservations;
      })
      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/reservations?return_date=tomorrow', { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        this.returns_tomorrow = response.data.reservations;
      })

    },
    get_locations() {},
  },
  mounted () {
    this.get_data();
  }
}
</script>

<style lang="scss">

.dashboard-wrap {
  width: 100%;
  color: #495057;
  background-color: #e9ecef;
  border: 1px solid #dee2e6;
  padding: 30px;
  margin-bottom: 20px;

  h3 {
    margin: 0 0 20px 0;
    font-size: 22px;
    font-weight: 600;
  }

  h4 {
    margin: 0;
    font-size: 18px;
    font-weight: 400;
  }

  table {
    border: 1px solid #dee2e6;
    background: rgba(255,255,255,0.3);

    td {
      font-size: 14px;
    }
  }
}

</style>
